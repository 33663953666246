
import React, { useEffect, useState, useCallback } from 'react';
import { CheckCircleIcon } from '@heroicons/react/20/solid'
import api from './Api';
import {loadStripe} from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { Navigate, useParams, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';


// example api usage:
// const response = await api.post('/create-checkout-session', { planId });
const isDevelopmentOrTest = process.env.NODE_ENV === 'development' | window.location.hostname.includes("app-test.hurai.nl");
const stripeKey = isDevelopmentOrTest ? 'pk_test_51On5mSBjJydyR57nsTwZjNqJYbFWzS7DYJnqjoHezjcYrXhQ38HlmTW2rJqeUb6cFeTRyZ3emVTgMgUdXkij1un3008amq6EKE' : 'pk_live_51On5mSBjJydyR57njMpnCryIcfsgDEJPeS375LPKH9OR45vSGf70QqrLNpAe7aQWBsKpL2Wdt6bp0d9sqyKfVgys00YDKxMbN5';
const stripePromise = loadStripe(stripeKey);




const CheckoutForm = () => {

    const { planId } = useParams();

    const fetchClientSecret = useCallback(async () => {
        try {
            const response = await api.post('/create-checkout-session', { planId });
            return response.data.clientSecret;
        } catch (error) {
            console.error('Error fetching client secret:', error);
            throw new Error('Failed to fetch client secret');
        }
    }, [planId]);
  
    const options = {fetchClientSecret};
  
    return (
      <div id="checkout">
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={options}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      </div>
    )
  }
  
  

  const Return = () => {
    const [status, setStatus] = useState(null);
    const [planId, setPlanId] = useState(null);
    const [customerEmail, setCustomerEmail] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const sessionId = urlParams.get('session_id');

        api.get(`/session-status?session_id=${sessionId}`)  // Adjusted to use api module
            .then((response) => {
                setStatus(response.data.status);
                setPlanId(response.data.planId);
                setCustomerEmail(response.data.customer_email);
                setLoading(false);
            })
            .catch((err) => {
                console.error('Failed to fetch session status:', err);
                setError(err);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error retrieving data! Please try again later.</p>;
    }

    if (status === 'open') {
        return <Navigate to={`/checkout/${planId}`}/>;
    }

    if (status === 'complete') {
        return (
            <section id="success">
                <p>We appreciate your business! A confirmation email will be sent to {customerEmail}.</p>
            </section>
        );
    }

    return <p>No information available.</p>;
};

 
  const StripeBuyButton = ({ planId }) => {
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleCheckout = async () => {
        try {
                navigate(`/checkout/${planId}`); 
            
        } catch (error) {
            console.error('Failed to initiate checkout:', error);
            setError('Failed to initiate checkout.');
        }
    };

    return (
        <div>
            <button onClick={handleCheckout} className="bg-primary-600 text-white p-2 rounded-lg">
                Buy Now
            </button>
            {error && <p className="text-red-500">{error}</p>}
        </div>
    );
};
  
const tiers = [
    {
        name: '1week',
        id: '1week',
        href: '#',
        planId: 'price_1weekAccess',
        descriptionKey: 'stripe.1weekDescription',
        originalPrice: '14.50 EUR',
        discountedPrice: '14.50 EUR',
        features: [
            'stripe.features.unlimited_questions',
            'stripe.features.upload_documents',
            'stripe.features.premium_support'
        ],
    },
    {
        name: '2week',
        id: '2week',
        href: '#',
        planId: 'price_2weekAccess',
        descriptionKey: 'stripe.4weekDescription',
        originalPrice: '29.50 EUR',
        discountedPrice: '19.50 EUR',
        coupon_code: 'BETATWOWEEKS',
        features: [
            'stripe.features.unlimited_questions',
            'stripe.features.upload_documents',
            'stripe.features.premium_support'
        ],
    },
    {
        name: '4week',
        id: '4week',
        href: '#',
        planId: 'price_4weekAccess',
        descriptionKey: 'stripe.4weekDescription',
        originalPrice: '59.00 EUR',
        discountedPrice: '29.50 EUR',
        coupon_code: 'BETAFOURWEEKS',
        features: [
            'stripe.features.unlimited_questions',
            'stripe.features.upload_documents',
            'stripe.features.premium_support',
            'stripe.features.huurcheck'
        ],
    },
];
  
const StripeComponent = () => {
    const { t } = useTranslation();

    return (
        <div className="bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-4xl sm:text-center">
                    <h2 className="text-base font-semibold leading-7 text-primary-900">{t('stripe.title')}</h2>
                    <p className="mt-2 text-4xl font-bold tracking-tight text-primary-900 sm:text-5xl">
                        {t('stripe.choose_plan')}
                    </p>
                </div>
                <div className="mt-20 flow-root">
                    <div className="isolate -mt-16 grid max-w-sm grid-cols-1 gap-y-16 divide-y divide-gray-100 sm:mx-auto lg:-mx-8 lg:mt-0 lg:max-w-none lg:grid-cols-3 lg:divide-x lg:divide-y-0 xl:-mx-4">
                        {tiers.map((tier) => (
                            <div key={tier.id} className="pt-16 lg:px-8 lg:pt-0 xl:px-14">
                                <h3 className="text-xl font-semibold leading-7 mb-4 text-gray-900">{t(`stripe.${tier.name}`)}</h3>
                                <StripeBuyButton planId={tier.planId} />
                                <p className="mt-10 text-sm font-semibold leading-6 text-gray-900">{t(tier.descriptionKey)}</p>
                                <p className="mt-2 text-sm leading-6 text-gray-900">
                                    <span className="text-secondary-800 text-md">{t('stripe.original_price')}</span> 
                                    <span className="ml-10 line-through text-secondary-800 text-lg">{tier.originalPrice}</span> 
                                </p>
                                <p className="mt-2 text-sm leading-6 text-gray-900">
                                    <span className=" text-primary-600 text-md">{t('stripe.discounted_price')}</span> 
                                    <span className="ml-4 text-2xl font-bold text-primary-600">{tier.discountedPrice}</span>
                                </p>
                                <p className="mt-2 mb-2 leading-6 text-gray-900">
                                {tier.coupon_code ? (
                                    <>
                                        <div className="h-20 mt-12">
                                            <p className="text-primary-600 text-md">{t('stripe.coupon_code')}:</p> 
                                            <p className="text-2xl font-bold text-primary-600">{tier.coupon_code}</p>
                                        </div>
                                    </>
                                ) : (
                                    <div className="h-20 mt-12"></div>
                                )}
                                </p>
                                <ul className="mt-6 space-y-3 text-sm leading-6 text-gray-600">
                                    {tier.features.map((feature) => (
                                        <li key={feature} className="flex gap-x-3">
                                            <CheckCircleIcon className="h-6 w-5 flex-none text-primary-600" aria-hidden="true" />
                                            {t(feature)}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
  
  export {StripeComponent, Return, CheckoutForm};